













































import { Component, Vue } from 'vue-property-decorator';
import UsersApi from '@/api/Users';
import User, { UserRole, UserStatus } from '@/models/User';
import SetNewPassword from '@/pages/protected/SetNewPassword.vue';

@Component({
  components: { SetNewPassword }
})
export default class ProtectedMain extends Vue {
  public currentUser: User|null = null;
  private menuItems = [
    { name: 'Videos', label: 'Videos', icon: 'video', route: '/app/videos' },
    { name: 'uploadVideos', label: 'Upload Videos', icon: 'upload', route: '/app/upload-videos' },
    { name: 'manageClub', label: 'Manage Club', icon: 'users', route: '/app/manage-club' }
  ];

  mounted() {
    UsersApi.getCurrentUser().then((user: User) => {
      this.currentUser = user;
      localStorage.setItem('userId', this.currentUser.id.toString());
      if (this.currentUser.status === UserStatus.PENDING) {
        this.$root.$emit('openModal', {
          title: 'Set New Password',
          canCancel: false,
          component: SetNewPassword,
          events: {
            'success': (user: User) => this.currentUser = user
          }
        });
      }
    }).catch((error) => {
      this.$root.$emit('handleError', error);
    });
  }

  public get visibleMenuItems() {
    const coachOnlyItems = ['uploadVideos', 'manageClub'];
    return this.menuItems.filter((menuItem) =>
      this.currentUser?.role === UserRole.COACH ||
      !coachOnlyItems.includes(menuItem.name)
    );
  }

  public get currentPage() {
    return this.$route.name;
  }

  public logout() {
    localStorage.setItem('loggedIn', 'false');
    localStorage.setItem('authToken', '');
    this.$http.defaults.headers.common['Authorization'] = null;
    this.$router.push('/login');
  }
}
