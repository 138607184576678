import axios from 'axios';
import Constants from '@/constants';
import User, { UserRole } from '@/models/User';
import { plainToClass } from 'class-transformer';

export default class UsersApi {
  public static async getCurrentUser(): Promise<User> {
    const response = await axios.get(`${Constants.api}/users/current`);
    return plainToClass(User, response.data);
  }

  public static async createUser(data: { email: string; username: string; role: UserRole }): Promise<User> {
    const response = await axios.post(`${Constants.api}/users/create`, data);
    return plainToClass(User, response.data);
  }

  public static async editUser(
    data: {id: number; email: string; username: string; role: UserRole }
  ): Promise<void> {
    return await axios.post(`${Constants.api}/users/edit`, data);
  }

  public static async deleteUser(userId: number): Promise<void> {
    return await axios.delete(`${Constants.api}/users/${userId}`);
  }

  public static async setCurrentUserPassword(newPassword: string): Promise<User> {
    return await axios.post(`${Constants.api}/users/current/setPassword`, { newPassword });
  }
}