import { Type } from 'class-transformer';
import { Club } from './Club';

export enum UserRole { ATHLETE = 'athlete', COACH = 'coach', ADMIN = 'admin' }
export enum UserStatus { ACTIVE = 'active', PENDING = 'pending', INACTIVE = 'inactive' }

export default class User {
  public id: number;
  public username: string;
  public email: string;
  public role: UserRole;
  public status: UserStatus;
  public tempPassword: string;

  @Type(() => Club)
  public club: Club;
}