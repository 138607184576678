

















import { Component, Vue } from 'vue-property-decorator';

enum MessageType { SUCCESS = 'success', ERROR = 'error' }

@Component
export default class GlobalAlert extends Vue {
  public message = '';
  public messageVisible = false;
  public messageType = MessageType.ERROR;
  mounted() {
    this.$root.$on('handleError', (errorMessage: string) => {
      this.showMessage(errorMessage, MessageType.ERROR);
      if (process.env !== 'production') {
        console.log(errorMessage);
      }
    });

    this.$root.$on('showSuccessMessage', (message: string) => {
      this.showMessage(message, MessageType.SUCCESS);
    });
  }

  public showMessage(message: string, type: MessageType) {
    this.message = message;
    this.messageVisible = true;
    this.messageType = type;
    setTimeout(() => this.messageVisible = false, 10000);
  }
}
