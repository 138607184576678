































import { Component, Prop, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';

export type ModalButton = {
  text: string;
  type: string;
  iconL?: string;
  iconR?: string;
  handler?: Function;
}

export interface ModalInfo {
  title: string;
  canCancel?: boolean;
  component?: Vue;
  content?: string;
  events?: object;
  props?: object;
  buttons?: Array<ModalButton>;
}

@Component({
  components: { VButton }
})
export default class Modal extends Vue {
  @Prop() hasComponent: boolean;
  public active = false;
  public title = '';
  public canCancel = true;
  public component: Vue|null;
  public content: string|null;
  public isComponentModal: boolean;
  public events: object;
  public props: object;
  public buttons: Array<ModalButton>;

  mounted() {
    this.$root.$on('openModal', (modalInfo: ModalInfo) => {
      this.active = true;
      this.title = modalInfo.title;
      this.canCancel = modalInfo.canCancel ?? true;
      this.component = modalInfo.component ?? null;
      this.content = modalInfo.content ?? null;
      this.isComponentModal = this.component !== null;
      this.buttons = modalInfo.buttons ?? [];
      this.props = modalInfo.props ?? {};

      this.events = {
        ...modalInfo.events ?? {},
        'close': () => this.close()
      };
    });
  }

  public buttonClicked(handler: Function) {
    if (handler !== undefined) handler();
    this.close();
  }

  public overlayClicked() {
    if (this.canCancel) this.active = false;
  }

  public close() {
    this.active = false;
  }
}
