


































import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import VMessage from '@/components/VMessage.vue';
import UsersApi from '@/api/Users';
import User from '@/models/User';

@Component({
  components: { VButton, VMessage }
})
export default class SetNewPassword extends Vue {
  public errors: string[] = [];
  public password = '';
  public password2 = '';
  public loading = false;

  public submit() {
    this.errors = [];
    
    if (this.password !== this.password2) {
      this.errors.push('Passwords do not match!');
    }

    if (this.password.length < 8 || this.password.length > 255) {
      this.errors.push('Password must be between 8 and 255 characters.');
    }

    if (this.errors.length > 0) return;

    UsersApi.setCurrentUserPassword(this.password).then((user: User) => {
      this.$emit('success', user);
      this.$emit('close');
    }).catch((error) => {
      this.$root.$emit('handleError', error);
    })
  }
}
