import { render, staticRenderFns } from "./VMessage.vue?vue&type=template&id=5616827a&scoped=true&"
import script from "./VMessage.vue?vue&type=script&lang=ts&"
export * from "./VMessage.vue?vue&type=script&lang=ts&"
import style0 from "./VMessage.vue?vue&type=style&index=0&id=5616827a&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5616827a",
  null
  
)

export default component.exports