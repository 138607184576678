















import { Component, Prop, Vue } from 'vue-property-decorator';

enum ButtonType { SUCCESS = 'success', INFO = 'info', DANGER = 'danger', LIGHT = 'light' }

@Component
export default class VButton extends Vue {
  @Prop() public type: ButtonType;
  @Prop() public iconLeft?: string;
  @Prop() public iconRight?: string;
  @Prop() public loading?: boolean;
  @Prop() public to?: string;
  @Prop({ default: '' }) public classList: string;
  @Prop({ default: false }) public dense: boolean;
  @Prop({ default: false }) public outlined: boolean;

  public get isLoading() {
    if (this.loading === undefined) return false;
    return this.loading;
  }

  public get padding() {
    return this.dense ? 'p-1' : 'p-2';
  }

  public get buttonClass() {
    return this.outlined ? 'outlined' : '';
  }

  public handleClick() {
    if (this.to !== undefined) {
      this.$router.push(this.to);
    } else {
      this.$emit('click');
    }
  }
}
