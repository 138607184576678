








import { Component, Vue } from 'vue-property-decorator';
import GlobalAlert from '@/components/GlobalAlert.vue';
import Modal from '@/components/Modal.vue';

@Component({
  components: { GlobalAlert, Modal }
})
export default class App extends Vue {
  mounted() {
    if (localStorage.getItem('authToken') !== null) {
      const authToken = localStorage.getItem('authToken');
      this.$http.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    }

    this.$http.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (this.$route.name !== 'login' && error.response.status === 401) {
        localStorage.setItem('loggedIn', 'false');
        localStorage.setItem('authToken', '');
        this.$http.defaults.headers.common['Authorization'] = null;
        this.$router.push('/login');
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    });
  }
}
