import 'reflect-metadata';
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './index.css';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight, faCaretDown, faCheck, faCircleNotch, faCog, faInfo, faPause, faPlay, faPlus,
  faSignOutAlt, faTrash, faUpload, faUsers, faVideo, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPlay, faPause, faCircleNotch, faArrowRight, faArrowLeft, faCheck, faCaretDown, faCog, faSignOutAlt, faVideo, faUpload, faUsers,
  faPlus, faInfo, faTrash, faEdit);
Vue.component('font-awesome-icon', FontAwesomeIcon);

import VModal from 'vue-js-modal';
Vue.use(VModal, { dialog: true, dynamicDefaults: { shiftY: 0.25, height: 'auto' } });

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
