









import { Component, Vue } from 'vue-property-decorator';
@Component
export default class PublicMain extends Vue {
  public loggedIn = localStorage.getItem('loggedIn') === 'true' ? true : false;

  mounted() {
    if (this.loggedIn) {
      this.$router.push('/app/videos');
    }
  }
}
