import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import PublicMain from '@/pages/public/Main.vue';
import ProtectedMain from '@/pages/protected/Main.vue';

Vue.use(VueRouter)

const publicRoutes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/public/Login.vue')
  },
  {
    path: '/new-club',
    name: 'newClub',
    component: () => import('../pages/public/NewClub.vue')
  }
];

const protectedRoutes: Array<RouteConfig> = [
  {
    path: 'videos',
    name: 'Videos',
    component: () => import('../pages/protected/Videos.vue')
  },
  {
    path: 'manage-club',
    name: 'manageClub',
    component: () => import('../pages/protected/ManageClub.vue')
  },
  {
    path: 'upload-videos',
    name: 'uploadVideos',
    component: () => import('../pages/protected/UploadVideos.vue')
  },
  {
    path: 'video-test/:videoId',
    name: 'videoTest',
    component: () => import('../pages/protected/VideoTest.vue'),
    props: true
  }
]

const routes: Array<RouteConfig>= [
  {
    path: '/',
    name: 'PublicMain',
    component: PublicMain,
    children: publicRoutes
  },
  {
    path: '/app',
    name: 'ProtectedMain',
    component: ProtectedMain,
    children: protectedRoutes
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
